import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import Component from "./SelectComponent.vue";

export default Node.create({
  name: "select-component",

  group: "inline",

  inline: true,
  atom: true,
  selectable: false,

  parseHTML() {
    return [
      {
        tag: "select-component",
      },
    ];
  },

  addAttributes() {
    return {
      opcoes: {
        default: "",
      },
      selecionado: {
        default: "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {//  node,   
    return ["select-component", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },  
});
