<template>
  <node-view-wrapper as="div" :id="idSelect" class="select_componet">
    <span contenteditable="false" @keydown="keyAction" class="select_value" tabindex="0">{{ node.attrs.selecionado == 0 ? "selecione uma opção" : node.attrs.selecionado  }}</span>
    <ul class="opcoes">
      <li :pos="idx" :class="{ 'selected': node.attrs.selecionado.trim() == item.trim()}" v-for="(item, idx) in node.attrs.opcoes.split('|')" :key="item"> 
      <strong v-if="idx<10">{{ idx }} </strong> - {{ item.trim()
      }}</li>
    </ul>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  data() {
    return {
      idSelect: "select_" + Math.random().toString(36).substring(2, 9),
      selecionado: this.node.attrs.selecionado || "selecione uma opção",
      currentLI: -1,
    };
  },
  components: {
    NodeViewWrapper
  },
  watch: {
    selecionado(val) {
      this.updateAttributes({ selecionado: val });
    }
  },
  methods: {
    setValue(li) {
      if (this.currentLI !== -1) {
        this.select.querySelectorAll('li').forEach(function (lit) {
          lit.classList.remove('selected');
        });
      }

      this.currentLI = parseInt(li.getAttribute('pos'));
      li.classList.add("selected");
      this.selecionado = li.innerHTML.substring(li.innerHTML.indexOf("-")+1).trim();
    },

    show() {
      this.select.querySelector('ul').classList.toggle('show');
    },
    keyAction(event){
      console.log("keyAction", event);
      console.log("???",event.keyCode, event.key, event.code);
      switch (event.keyCode) {
        case 27:
          event.preventDefault();
          this.select.querySelector('ul').classList.remove('show');
          break;
        case 13:
          event.preventDefault();
          this.select.querySelector('ul').classList.remove('show');
          var fieldSelect = 0;
          var selects = document.querySelectorAll('span.select_value')
          selects.forEach((element, idx) => {
            if (element == document.activeElement) {
              fieldSelect = idx;
            }
          });
          if (fieldSelect < selects.length - 1) {
            selects[fieldSelect + 1].focus();
          }
          break;
        case 38:
          event.preventDefault();
          if (this.currentLI == -1) {
            this.currentLI = 0;
          } else {
            this.currentLI = this.currentLI > 0 ? --this.currentLI : 0;
          }
          this.setValue(this.listItems[this.currentLI]);
          break;
        case 40:
          event.preventDefault();
          if (this.currentLI == -1) {
            this.currentLI = 0;
          } else {
            this.currentLI = this.currentLI < this.listItems.length - 1 ? ++this.currentLI : this.listItems.length - 1;
          }
          this.setValue(this.listItems[this.currentLI]);
          break;
      }
      if ((event.keyCode >= 48 && event.keyCode <= 57)
        || (event.keyCode >= 96 && event.keyCode <= 105)) {
        event.preventDefault();
        const num = event.key;
        console.log(num);
        if (this.listItems.length > num)
          this.setValue(this.listItems[num]);
      }      
    }

  },
  mounted() {
    const self = this;
    setTimeout(() => {

      self.select = document.querySelector(`#${self.idSelect}.select_componet`);
      self.input = self.select.querySelector('.select_value');
      self.listItems = self.select.querySelectorAll(".opcoes li");

      self.input.addEventListener('click', () => {
        self.input.focus();
      });

      self.input.addEventListener('focus', self.show);
      self.input.addEventListener('blur', () => {
        setTimeout(() => self.select.querySelector('ul').classList.remove('show'), 200);
      });

      self.select.querySelectorAll('li').forEach(function (li) {

        li.addEventListener('click', function () {
          self.setValue(li);
          self.select.querySelector('ul').classList.remove('show');
        });
      });

    }, 200);
    
  },
  props: nodeViewProps
};
</script>

<style lang="scss">

.select_componet {
  position: relative;
  word-wrap: break-word;
  display: inline;
}
.select_value {
  min-width: 50px;
  background-color: #ffee58;
  color: #000000;
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0 3px;  
}

.select_value:focus {
  outline: 2px solid #fdd835;
  cursor: pointer;
}

.select_value:hover {
  outline: 2px solid #fdd835;
  cursor: pointer;
  caret-color: red !important;
}

.opcoes {
  position: absolute;
  background-color: #ffee58;
  border: 1px solid #fdd835;
  color: #000000;
  border-radius: 5px;
  padding: 0px !important;
  margin-bottom: 10px;
  display: none;
  margin-top: 2px;
  z-index: 1000;
  min-width: 400px;
}

.opcoes li {
  list-style: none;
  padding: 5px;
  border-bottom: 1px solid #fdd835;
}

.opcoes li:hover {
  background-color: #fdd835;
  cursor: pointer;
}

.opcoes li.selected {
  background-color: #fdd835;
  cursor: pointer;
}

.show {
  display: block !important;
}
</style>
